import React from "react";
import { useNavigate } from "react-router-dom";
import CenteredTextField from "../../../components/common/Field/CenteredTextField";
import logo from "../../../assests/images/connexLogo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  ForgotPasswordApi,
  ForgotPasswordEmailApi,
} from "../../../redux/action/authAction";
const Confirmemail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .email("Invalid Email Format"),
    }),
    onSubmit: (values) => {
      let val = {
        email: values.email,
      };
      dispatch(ForgotPasswordEmailApi(val, navigate));
    },
  });

  return (
    <>
      {" "}
      <div>
        <img
          src={logo}
          alt="Logo"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "200px", // Adjust the width as needed
            height: "auto", // Maintain aspect ratio
          }}
        />
        <div className="loginCard">
          <div className="loginContainer">
            <h2>Forgot Password</h2>
            {/* <p>Enter your Email we will sent a OTP to reset your Password</p> */}
            <br />

            <CenteredTextField
              label="Email"
              id="email"
              placeholder="Enter your Email"
              formik={formik}
            />
            <br />
            <button className="loginBtn" onClick={formik.handleSubmit}>
              <b> C o n t i n u e </b>
            </button>
            <br></br>
            <br />
            <p>
              <span style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                Back To Login
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmemail;
